import React, { Component } from 'react';
import { compose, Dispatch } from 'redux';
import { wrapper } from '../src/reduxWrapper';
import ContentContainer from "../src/components/Common/ContentContainer";
import {Image} from "semantic-ui-react";
import ReactMarkdown from 'react-markdown'


const coverImage = require('public/cover_image.png');

interface MainListPageProps {
  loadListDataStart: () => void;
  loadListDataLoading: boolean;
  loadListDataError: boolean;
  loadListDataErrorMessage: string;
}

interface MainListPageState {
  options: any[];
}

class MainListPage extends Component<MainListPageProps, MainListPageState> {
  public render() {
    const {
      loadListDataLoading,
    } = this.props;
    if (loadListDataLoading) {
      return (<div>loading...</div>);
    }
    const markDown = `안녕하세요! 반려동물 사진 대결 서비스 니가개냥 입니다. 

우리 모두는 알고 있어요, 귀여운 것들이 세상을 지배한다는 것을 🐣

니가개냥에서 귀여운 동물들을 모아보고 힐링하세요 ☺️

# 🐶 서비스 소개

---

![/main_1.png](/main_1.png)

## 다양한 매치에 참가하세요

니가개냥엔 다채로운 귀여움을 맛볼 수 있는 다양한 매치가 준비되어 있어요.

다양한 매치에 참가하고 나의 마음을 사로잡을 수 있는 귀여움을 발견하세요! 

![/main_2.png](/main_2.png)

## 귀여움을 대결하세요

각 매치에는 수 많은 귀여운 동물들이 출전해있어요. 

한 번에 16마리씩 대결을 펼쳐 최고의 귀여움을 가리고, 참가 동물이 많다면 매치에 또 참가할 수 있어요.

매치 대결에서 선택을 받은 동물에겐 승점이 쌓여요.

![/main_3.png](/main_3.png)

## 귀여움 랭킹을 확인하세요

승점을 많이 쌓은 동물들은 더 높은 순위에 랭크 되어요. 

각 매치 별 순위를 볼 수 있는 \`매치랭킹\`과 모든 동물의 순위를 볼 수 있는 \`전체랭킹\`을 확인해보세요!

랭킹 페이지에선 각 동물들의 사진을 확대해서 감상할 수 있어요! 

![/main_4.png](/main_4.png)

## 나의 반려동물도 출전시켜보세요!

세상에서 제일 귀여운 우리집 댕댕이, 냥냥이를 세상에 자랑해보세요!

혹시 모르죠? 우리집 아이가 랭킹 1등을 할지도 👀

# 🎯 이런 기능이 추가될거에요

---

- 로그인 기능 : 간편 로그인을 통해 유저 계정을 생성할 수 있어요
- 웹으로 공유하기 기능 : 친구들에게 매치를 공유 시 웹으로 간단히 매치에 참여할 수 있어요
- 매치 만들기 기능 : 내가 원하는 매치를 만들 수 있어요
- 펫 프로필 기능 : 귀여운 펫들의 개별 프로필을 만들 수 있어요
- 사진 저장하기 기능 : 내가 좋아하는 강아지, 고양이의 사진을 저장할 수 있어요
- 그 외 귀여움을 증폭 시킬 기능들 🤩

# ✋🏻 문의하기 / 매치 제안하기

---

니가개냥에 궁금한 점이 있거나, 새로운 매치를 만들길 원하신다면 니가개냥 카카오톡 채널로 문의해주세요! 

👉🏻  [카카오톡 문의 바로가기](http://pf.kakao.com/_VeNKK/chat)

[매치 제안하기](https://www.notion.so/c00d8f8ebe0f4823adc2962a119e0d5f)

[니가개냥 이용약관](https://www.notion.so/60a316c3e41d4accb2aa352854457839)

[니가개냥 개인정보취급방침](https://www.notion.so/d1b1d489e3594b0097fc104ff3c5a434)

# 🤩 지금 다운로드 받기

---

- 안드로이드 다운로드 : [http://bit.ly/3mC2sex](http://bit.ly/3mC2sex)
- iOS 다운로드 : [http://apple.co/3pf21bM](http://apple.co/3pf21bM)
    `
    return (
      <ContentContainer>
        <h2><a href={"http://onelink.to/6c39we"}>니가개냥 설치 링크</a></h2>

        <ReactMarkdown children={markDown} />
        <Image wrapped size='large' src={coverImage}/>
      </ContentContainer>
    );
  }
}



export default compose(wrapper.withRedux)(MainListPage);
